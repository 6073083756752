import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { Footer } from '../components/footer/Footer';
import { MyNavbar } from '../components/navbar/Navbar';
import { Error404 } from '../pages/404';
import { About } from '../pages/About';
import { Contact } from '../pages/Contact';
import { Home } from '../pages/Home';

export const WebRouter = () => {
    React.useEffect(() => {
        if (window.location.pathname !== '/' &&
            window.location.pathname !== './' &&
            window.location.pathname !== '/inicio' &&
            window.location.pathname !== '/about' && window.location.pathname !== '/contacto' && window.location.pathname !== '/404') {
            return window.location.replace('/404')
        }
    }, [])
    return (
        <>

            {window.location.pathname !== '/' &&
                window.location.pathname !== '/inicio' &&
                window.location.pathname !== '/about' && window.location.pathname !== '/contacto' && window.location.pathname === '/404' ? <Error404 /> :


                <> < MyNavbar />
                    <Routes >
                        <Route path="about" element={<About />} />
                        <Route path="/contacto" element={<Contact />} />
                        <Route path="inicio" element={<Home />} />
                        <Route path="/" element={<Home />} />
                        <Route
                            path="*/" element={<Home />}
                        />

                    </Routes>
                    <Footer />
                </>}



        </>
    )
}
