import { motion } from "framer-motion";
import React from "react";
import ReactPlayer from "react-player";
import { MyCarousel } from "./Carousel";
import { MyCarousel2 } from "./Carousel2";
export const Biografia = () => {
  return (
    <div className="row">
      <div>
        <h3>Biografía</h3>
        <p className="font-bold text-presentation mt-4 mb-4">
          Mi nombre es Ezequiel López. Realicé mis estudios secundarios en la
          E.P.E.T. N°20 en Neuquén. Estoy instruido en programación web y
          desarrollo de aplicaciones mobile. Tengo conocimientos en{" "}
          <u>Javascript</u>, Flutter, <u> React</u>, Nodejs, creación de Rest
          API, gestión de proyectos, scrum y continúo formándome todos los días
          tanto a nivel universitario y personal cómo a nivel profesional. Me
          encuentro muy a gusto con la profesión que ejerzo, me entusiasma
          mucho. En mis tiempos libres me gusta componer música, leer acerca de
          finanzas, gestión empresarial, inversión en bolsa o artículos
          referidos al liderazgo y dirección de equipos. Actualmente estoy
          estudiando la carrera Licenciatura en sistemas de la información en la
          Universidad Nacional del Comahue en Neuquén.
        </p>
        <p className="font-bold text-presentation mt-4 mb-4">
          En 2020 escribí "El secreto del promedio deseado" durante la pandemia.
          Mientras transcurria la secundaria pude observar que la causa
          principal de la desmotivación en un estudiante es la falta de
          atracción hacia la <u>orientación en la que se encuentra</u>. Al ver
          esto me he preguntado ¿Cómo puedo transmitir mi motivación al resto?.
          Teniendo esta pregunta en mente en mi tiempo libre diseñé un escrito
          en el cual expreso las estrategias que he llevado a cabo, los valores
          que me enseñaron en casa y mi forma de ver y afrontar la escuela. Si
          quieres saber más puedes ir al apartado de "Logros" al final de la
          página en el cual obtendrás más información.
        </p>
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ velocity: 0.5 }}
        >
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Fvideo.mp4?alt=media&token=839619b5-5ce4-4a99-970d-38e3090def49"
            width="100%"
            height="100%"
            controls
            muted
          />
        </motion.div>
        <p className="text-muted text-center">
          Video capturado en la feria de orientación vocacional de E.P.E.T N°20
          Ministerio de educación - Año 2021.
        </p>
        <p className="font-bold text-presentation mt-4">
          En 2021 brindé charlas de orientación vocacional en mi escuela y en la
          feria. Ese día obtuve una nota por parte del ministerio de educación y
          en la semana el diario La Mañana Neuquén se interesó en mi proyecto y
          decidió publicar mi historia. Minuto Neuquén hizo lo mismo luego.
        </p>
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            className="underline"
            href="https://www.lmneuquen.com/un-estudiante-hizo-un-libro-ayudar-sus-companeros-terminar-la-secundaria-n864125#:~:text=%E2%80%9CEl%20secreto%20del%20promedio%20deseado,motivaci%C3%B3n%20para%20concluir%20la%20secundaria."
          >
            Ver la nota de La Mañana Neuquén
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.minutoneuquen.com/neuquen/2021/11/27/manual-de-supervivencia-escolar-version-neuquina-un-estudiante-da-tips-para-terminar-la-escuela-301746.html"
            className="underline ml-4"
          >
            Ver nota de Minuto Neuquén
          </a>
        </div>
        <motion.div
          className="mt-4"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ velocity: 0.5 }}
        >
          {" "}
          <MyCarousel2 />
        </motion.div>

        <motion.div
          className="mt-4"
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ velocity: 0.5 }}
        >
          <iframe
            src="https://www.youtube.com/embed/XeQeGKMYow0"
            title="YouTube video player"
            frameBorder="0"
            width="100%"
            height="315px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="video-fluid grid align-items-center"
          ></iframe>
        </motion.div>
        <p className="font-bold text-presentation mt-4">
          Luego de estas experiencias y gracias a mi gran logro fui invitado
          junto a una compañera a una reunión nacional para mejorar la educación
          técnica de todas las provincias en el establecimiento del Instituto
          nacional de educación técnológica (I.N.E.T.) en Buenos Aires en la
          cual se dialogó principalmente acerca de la mejora de E.S.I.
          (Educación sexual integral).
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          className="underline"
          href="https://www.neuquen.edu.ar/estudiantes-de-la-epet-20-participaron-del-encuentro-nacional-de-jovenes-de-educacion-tecnica-profesional/"
        >
          Ver Comunicado del Ministerio de Educación de Neuquén
        </a>
        <motion.div
          exit={{ opacity: 0 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ velocity: 0.5 }}
          className="mt-4"
        >
          <MyCarousel />
        </motion.div>
      </div>
    </div>
  );
};
