import React from "react";
import "./custom_navbar.css";
import "./custom_navbar.css";
import logo from "../../assets/logo-min.svg";
import { Link } from "react-router-dom";
export const HeaderNav = ({ click, disable }) => {
  return (
    <>
      <nav className="my-navbar hide-inPc ">
        <div className=" full-width">
          <div className="display-bar w-100 ">
            <div className="brand w-100">
              <div className="brand-items p-2 w-100 border">
                <button onClick={click} className=" hide-inPc">
                  <h3 className="menu-icon font-bold">|||</h3>
                </button>
                <div className="d-flex  text-center ">
                  <Link to="/inicio">
                    <img
                      src={logo}
                      alt="Logo de Ezequiel López"
                      className="logoSvg"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
