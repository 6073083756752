import React, { useEffect, useState } from "react";
import "./custom_navbar.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { HeaderNav } from "./Header";
import logo from "../../assets/logo.svg";
export const MyNavbar = () => {
  const [menu, setMenu] = useState(false);
  const [screenWidth, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const showMenu = () => {
    setMenu(!menu);
  };

  /*
    Navbar ES LA SECCIÓN DE 
    LA PÁGINA DONDE SE ENCUENTRA 
    EL LOGO Y EL MENÚ DE NAVEGACIÓN
    */

  return (
    <>
      <HeaderNav
        click={() => showMenu()}
        disable={() => (menu ? showMenu(false) : "")}
      />
      {menu || screenWidth > 1365 ? (
        <>
          <nav className="my-navbar  me-auto">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.1 }}
              className="menu"
            >
              <div className="display-bar ">
                <div className="brand">
                  <div className="brand-items ">
                    <button onClick={() => showMenu()} className=" hide-inPc">
                      <h3 className="menu-icon font-bold">|||</h3>
                    </button>
                    <img
                      src={logo}
                      alt="Logo de Ezequiel López"
                      className="logoSvg mb-2 ml-4 hide-inMobile"
                    />
                    <h1 className=" logo-title d-flex navbar-brand hide-inMobile main-color font-bold  ml-5 mb-2">
                      <Link
                        onClick={() => setMenu(false)}
                        to="/inicio"
                        className="ml-5 hide-inMobile"
                      >
                        {" "}
                        Ezequiel López
                      </Link>
                    </h1>
                  </div>
                </div>
              </div>
              {/* NAVBAR ITEMS */}
              {menu || screenWidth > 1365 ? (
                <>
                  <div id="menu" className="elements">
                    <div className="items ">
                      <Link
                        onClick={() => showMenu()}
                        to="/inicio"
                        className=" ft-size nav-element font-bold"
                      >
                        Inicio
                      </Link>
                      <Link
                        onClick={() => showMenu()}
                        to="about"
                        className=" ft-size nav-element font-bold "
                      >
                        Acerca de mi
                      </Link>

                      <Link
                        onClick={() => showMenu()}
                        to="contacto"
                        className="ft-size nav-element font-bold"
                      >
                        Contacto
                      </Link>

                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.tintalibre.com.ar/book/820/El_secreto_del_promedio_deseado"
                        className="ft-size nav-element font-bold"
                      >
                        El secreto del promedio deseado
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://drive.google.com/file/d/1shP3P8GcKXcy72a_zlGAdGz4Ei6y_wm9/view?usp=sharing"
                        className="btn btn-primary main-color mt-2 font-bold"
                      >
                        Descargar currículum
                      </a>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </motion.div>
            <motion.div
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="blackShadow hide-inPc"
              onClick={() => showMenu(false)}
            ></motion.div>
          </nav>
        </>
      ) : (
        ""
      )}
    </>
  );
};
