import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input, FormFeedback, Alert } from "reactstrap";
import { useForm } from "../../hooks/useForm";
import emailjs from "@emailjs/browser";
import { LoadingSpinner } from "../loading/loading";
export const SendEmail = () => {
  const { handleChange, values } = useForm();
  const { name, email, mensaje, asunto } = values;
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [warning, setWarning] = useState(false);
  const form = useRef();
  const sendEmail = () => {
    setLoading(true);
    emailjs
      .send(
        "gmail",
        "emailSender",
        {
          name: name,
          email: email,
          mensaje: mensaje,
          asunto: asunto,
        },
        "user_NJiWikKLz9iJJ1pND6zxU"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setSubmit(true);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleEmail = (e) => {
    e.preventDefault();
    if (name && email && asunto && mensaje) {
      sendEmail();
    } else {
      setMessage("Todos los campos son obligatorios.");
      setWarning(true);
      setTimeout(() => {
        setWarning(false);
      }, 3000);
    }
  };
  return (
    <>
      {warning ? <Alert color="warning">{message}</Alert> : ""}
      {submit === false ? (
        <>
          <Form className="me-auto" ref={form} onSubmit={handleEmail}>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2">Nombre*</Label>
              <Input
                invalid={name === ""}
                value={name}
                name="name"
                type="text"
                placeholder="Ingrese un nombre."
                onChange={handleChange}
              />
              <FormFeedback invalid>Debe ingresar un nombre</FormFeedback>
            </FormGroup>

            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label className="me-sm-2">Email*</Label>
              <Input
                value={email}
                invalid={email === ""}
                name="email"
                placeholder="ej: jhondoe@gmail.com"
                type="email"
                onChange={handleChange}
              />
              <FormFeedback invalid>Debe ingresar un email válido</FormFeedback>
            </FormGroup>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label>Asunto*</Label>
              <Input
                value={asunto || ""}
                invalid={asunto === ""}
                name="asunto"
                placeholder="ej: Cotización de diseño web personal empresarial"
                type="text"
                onChange={handleChange}
              />
              <FormFeedback invalid>Debe ingresar un asunto</FormFeedback>
            </FormGroup>
            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
              <Label>Mensaje*</Label>
              <Input
                value={mensaje || ""}
                invalid={mensaje === ""}
                name="mensaje"
                type="textarea"
                placeholder="Tu mensaje"
                onChange={handleChange}
              />
              <FormFeedback invalid>Debe ingresar un mensaje</FormFeedback>
            </FormGroup>
            <input
              type="submit"
              className="btn btn-outline-primary font-bold mt-4"
              value="Realizar consulta"
            />
          </Form>
          {loading ? <LoadingSpinner text="Enviando..." /> : ""}
        </>
      ) : (
        <>
          <h1 className="p-4 text-center font-bold w-100 ">
            {"Email enviado exitosamente.".toUpperCase()}
          </h1>
          <h2 className="text-center p-4">
            En el transcurso de los días me podre en contacto con usted.
          </h2>
          <h3 className="text-center font-bold">¡Tenga un buen día!</h3>
        </>
      )}
    </>
  );
};
