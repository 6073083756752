import React from "react";


export const MyCarousel = () => {
  return (
    <div
      id="carouselExampleCaptions2"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions2"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions2"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner rounded-xl shadow-xl">
        <div className="carousel-item active">
          <img
                       src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Fhablando%20en%20INET.jpg?alt=media&token=346504c2-2fcf-4acc-85aa-78fdddd46b37"

            className="d-block w-100"
            alt="Ezequiel López - Dialogando en el I.N.E.T. - 2021"
          />
          <div className="carousel-caption d-none d-md-block rounded-md bg-dark">
            <h5 className="font-bold">Jóvenes en la ETP - 2021</h5>
            <p className="font-bold">
              Brindando una charla acerca del sistema educativo de Neuquén.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Fperfil%20INET.jpg?alt=media&token=756448ad-7800-4b7f-8b2a-ee9e6e2ac8d5"
            className="d-block w-100"
            alt="Ezequiel López - Trabajo en comisiones - Ministerio de educación - ETP 2021"
          />
          <div className="carousel-caption d-none d-md-block rounded-md bg-dark">
            <h5>Trabajo en comisiones entre provincias</h5>
            <p>
              Expresando la problemáticas generales de las provincias que
              participaron en la comisión a los representantes de cada provincia
              de la institución.{" "}
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions2"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
