import React from "react";
import reactjs from "../../assets/1.png";
import nodejs from "../../assets/3.png";
import flutter from "../../assets/2.png";
export const Tecnologías = () => {
  return (
    <div className="row  row-cols-1 row-cols-md-3 g-4 m-4">
      <div className="col ">
        <div className="card card-width rounded-xl">
          <img
            src={reactjs}
            className="card-img-top"
            alt="Ezequiel López - Reactjs logo"
          />
          <div className="card-body">
            <h5 className="card-title mb-2 text-muted font-bold">Reactjs</h5>
            <p className="text-presentation text-dark">
              Es una libreria de Javascript diseñada por Facebook para el diseño
              de interfaces de usuario basados en{" "}
              <a
              target="_blank"  rel="noreferrer"
                className="underline"
                href="https://es.wikipedia.org/wiki/Single-page_application"
              >
                SPA
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card card-width rounded-xl">
          <img
            src={nodejs}
            className="card-img-top"
            alt="Ezequiel López - Nodejs logo"
          />
          <div className="card-body">
            <h5 className="mb-2 text-muted font-bold">Node Js</h5>
            <p className=" text-presentation text-dark">
              Es un entorno en tiempo de ejecución orientado al servidor
              diseñado para crear aplicaciones escalables.
            </p>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card card-width rounded-xl">
          <img
            src={flutter}
            className="card-img-top"
            alt="Ezequiel López - Flutter logo"
          />
          <div className="card-body">
            <h5 className="mb-2 text-muted font-bold">Flutter</h5>
            <p className=" text-presentation text-dark">
              Es un{" "}
              <a
              target="_blank"  rel="noreferrer"
                className="underline"
                href="https://es.wikipedia.org/wiki/Flutter_(software)"
              >
                SDK
              </a>{" "}
              diseñado por Google para el desarrollo de aplicaciones móvil y
              multiplataforma.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
