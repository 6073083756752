import React from "react";
export const MyCarousel2 = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner rounded-xl shadow-xl ">
        <div className="carousel-item active">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2F2.png?alt=media&token=0e91ee74-aeb0-4152-8696-d2f2e8d02e07"
            className="d-block w-100"
            alt="Ezequiel López - Nota acerca de El secreto del promedio deseado"
          />
          <div className="carousel-caption d-none d-md-block rounded-md bg-dark">
            <h5>Nota de La Mañana Neuquén - 2021</h5>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2F1.png?alt=media&token=b4d21428-f53b-4d56-ac3f-21052b158a1a"
            className="d-block w-100"
            alt="Ezequiel López - Nota de minuto neuquén - 2021"
          />
          <div className="carousel-caption d-none d-md-block rounded-md bg-dark">
            <h5>Nota de Minuto Neuquén - 2021</h5>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};
