import React from "react";
import { ListGroupItem, ListGroup } from "reactstrap";
export const SocialMedia = () => {
  return (
    <ListGroup>
      <ListGroupItem action href="https://wa.me/message/5ZUHKTDSBHFFB1" tag="a">
        WhatsApp
      </ListGroupItem>
      <ListGroupItem action href="https://github.com/lopez15-hub" tag="a">
        Github
      </ListGroupItem>
      <ListGroupItem action href="mailto:contacto@ezequiellopez.ar" tag="a">
        Email
      </ListGroupItem>
      <ListGroupItem
        action
        href="https://www.linkedin.com/in/ezequiel-l%C3%B3pez-94193b1b9/"
        tag="a"
      >
        LinkedIn
      </ListGroupItem>
      <ListGroupItem
        action
        href="https://www.instagram.com/ezequiellopez.ar/"
        tag="a"
      >
        Instagram
      </ListGroupItem>
      <ListGroupItem
        action
        href="https://www.facebook.com/EzequielLopez346"
        tag="a"
      >
        Facebook
      </ListGroupItem>
      <ListGroupItem action href="https://twitter.com/ezequiellopezAR" tag="a">
        Twitter
      </ListGroupItem>
    </ListGroup>
  );
};
