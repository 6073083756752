import React from "react";

import { Link } from "react-router-dom";
import profile from "../../assets/perfil.png";

export const Presentation = () => {
  return (
    <div className=" container">
      <div className="">
        <div className="bg-white rounded-xl container full-mobile shadow-md w-50 p-4 mt-4">
          <div className="profile-img shadow-xl bg-primary rounded-50">
            <img
              src={profile}
              alt="Ezequiel López - Neuquén - Argentina "
              className="p-2 rounded-50 grid justify-items-center"
            />
          </div>
          <h5 className="font-bold text-center text-black text-presentation mt-4 mb-4">
            Ezequiel López
          </h5>
          <p className="font-bold text-center text-black text-presentation mt-4 mb-4">
            Programador web y desarrollador de aplicaciones mobile con Flutter y
            Dart.
          </p>

          <Link
            to="/about"
            className="btn btn-primary main-color mb-4 font-bold grid align-items-end"
          >
            Ver más
          </Link>
        </div>
      </div>
    </div>
  );
};
