import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiOutlineGithub,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-min.svg";
export const Footer = () => {
  const currentDate = new Date();
  const year = currentDate.toLocaleDateString("es-ES", { year: "numeric" });
  return (
    <footer className=" mt-4 bg-dark p-4 container-fluid ">
      <div className="d-flex  text-center mb-4">
        <Link to="/inicio" className="text-white font-bold d-flex">
          <img src={logo} alt="Logo de Ezequiel López" className="logoSvg" />
          <h3 className="mt-2">Ezequiel López</h3>
        </Link>
      </div>
      <h5 className="font-bold text-white">Correo electrónico</h5>
      <ul className="mb-4 mt-2">
        {" "}
        <li className="text-white">
          <h4> contacto@ezequiellopez.ar</h4>
        </li>
      </ul>
      <h5 className="font-bold text-white">¡Envía un mensaje!</h5>
      <ul className="mb-4 mt-2">
        {" "}
        <li className="text-white">
          <h4>+54 9 299 656 2742 </h4>
        </li>
      </ul>
      <h5 className="font-bold text-white">Redes Sociales</h5>
      <ul className="d-flex">
        <li className=" p-2 text-white">
          <a
            href="https://wa.me/message/5ZUHKTDSBHFFB1"
            alt="Link de whatsapp de Ezequiel López"
            target="_blank"
            rel="noreferrer"
          >
            <AiOutlineWhatsApp className="gh-icon" />
          </a>
          <a href="https://github.com/lopez15-hub">
            <AiOutlineGithub className=" gh-icon" />
          </a>
        </li>

        <li className=" text-white p-2">
          <a href="https://www.linkedin.com/in/ezequiel-l%C3%B3pez-94193b1b9/">
            <AiFillLinkedin className="gh-icon" />
          </a>
        </li>
        <li className=" text-white p-2">
          <a href="https://instagram.com/ezequiellopez.ar ">
            {" "}
            <AiFillInstagram className="gh-icon" />
          </a>
        </li>
        <li className=" text-white p-2">
          <a href="https://www.facebook.com/EzequielLopez346">
            <AiFillFacebook className="gh-icon" />
          </a>
        </li>
        <li className=" text-white p-2">
          <a href="https://twitter.com/ezequiellopezAR">
            <AiFillTwitterCircle className="gh-icon" />
          </a>
        </li>
      </ul>
      <p className="font-bold text-white text-center">
        {year} Pagina oficial de Ezequiel López. Todos los derechos reservados.
      </p>
    </footer>
  );
};
