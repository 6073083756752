import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Biografia } from "../components/about/Biografia";

export const About = () => {
  useEffect(() => {
    document.title = "Acerca de mi - Ezequiel López";
  }, []);
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ velocity: 0.5 }}
    >
      <div className="background ">
        <div>
          <h1 className="text-white title text-center font-bold w-100 ">
            {"Acerca de mi".toUpperCase()}
          </h1>
        </div>
      </div>
      <div className="w-100 bg-white shadow-xl rounded-xl container mb-4 mt-4">
        <div className="container">
          <div className="row p-4 w-100">
            <Biografia />

            <h1 className="text-center mt-4">Logros</h1>

            <motion.div className="container">
              <div
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ velocity: 0.5 }}
                className="row"
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Flibro.png?alt=media&token=1db55ee2-a2b9-4110-94e9-0a7a417f9917"
                  className="card-img-top libro m-2 h-100 col-md-4 col-sm-12"
                  alt="Libro el secreto del promedio deseado - Ezequiel López"
                />
                <div className="card-body col-2">
                  <h5 className="card-title font-bold">
                    Libro - El secreto del promedio deseado
                  </h5>
                  <p className="card-text text-wrap  ">
                    ¿Tienes metas? ¿Quieres cumplirlas? ¿Te gustaría encontrar
                    la forma de motivarte para pasar de año? En este libro,
                    compartiré contigo los métodos y estrategias que utilizo a
                    diario para lograr lo que llamo "el éxito escolar", así como
                    también lo que me decían mis papás respecto a la escuela. En
                    este libro desarrollo mi punto de vista sobre los
                    profesores, mis recomendaciones y también opiniones de otras
                    personas. Todo para lograr orientarte y que puedas encontrar
                    el camino para promocionar el año ¡de la mejor forma!
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="font-bold"
                    href="https://www.tintalibre.com.ar/book/820/El_secreto_del_promedio_deseado"
                  >
                    Ir a la página de la editorial
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
