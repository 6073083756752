import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Presentation } from "../components/home/Presentation";
import { Projects } from "../components/home/Projects";
import { Contact } from "./Contact";

import { Tecnologías } from "../components/home/Tecnologias";
export const Home = () => {
  useEffect(() => {
    document.title = "Inicio - Ezequiel López";
  }, []);
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ velocity: 0.5 }}
    >
      <div className="background  grid justify-items-center rounded-xl ">
        <div className="container">
          <h1 className="text-white title text-center  font-bold ">
            {"¡Bienvenido! ¿En qué puedo ayudarte?".toUpperCase()}
          </h1>
        </div>
      </div>
      <div className="text-white container">
        <Presentation />
      </div>
      <div className="container">
        <h1 className="text-white title text-center  font-bold mb-4">
          {"Tecnologías con las que trabajo".toUpperCase()}
        </h1>
      </div>
      <div className="container">
        <div className="mt-4 container p-4 w-70">
          <Tecnologías />
        </div>
      </div>
      <div className="container">
        <h1 className="text-white title text-center  font-bold mb-4">
          {"Proyectos".toUpperCase()}
        </h1>
      </div>
      <div className="container">
        <div className="mt-4 container w-70">
          <Projects />
        </div>
      </div>
      <div className="mt-4">
        <Contact />
      </div>
    </motion.div>
  );
};
