import React from "react";

export const Projects = () => {
  return (
    <div className="mt-4 mb-4 p-4">
      <div className="align-center row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card b-none shadow-xl">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Favisar.png?alt=media&token=3f0f13e4-de31-4d47-a8a1-91768c930036"
              className="card-img-top "
              alt="Imágen de AvisAR - Ezequiel López"
            />
            <div className="card-body">
              <h5 className="card-title font-bold">AvisAR</h5>
              <p className="card-text">
                AvisAR, es un aplicación que he realizado cómo proyecto de fin
                de año de mi escuela para informar los problemas de seguridad en
                la ciudad de Neuquén. fue desarrollado con Flutter y cuenta con
                la integración de Google maps y Firebase. Para informar
                únicamente aprietas en el mapa, rellenás el formulario y ¡listo!
                el problema de seguridad será visible para los demás usuarios.
              </p>
            </div>
            <a
            target="_blank"  rel="noreferrer"
                  className="btn btn-primary btn-lg m-4"
                  href="https://drive.google.com/file/d/1eVmOT9xMKISnOErDVyg6wIOA11Moqj6f/view?usp=sharing"
                >
                  Descargar AvisAR.
                </a>
          </div>
        </div>
        <div className="col">
          <div className="card b-none shadow-xl">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/ezequiellopez-6f484.appspot.com/o/assets%2Fbg20.png?alt=media&token=3324c246-d18a-48a5-a760-8fc2c9c3da85"
              className="card-img-top"
              alt="Web de la E.P.E.T. N°20 - Ezequiel López"
            />
            <div className="card-body">
              <h5 className="card-title font-bold">Web estilo escolar</h5>
              <p className="card-text">
                Este es un proyecto que estoy llevando a cabo actualmente. Es un
                desarrollo de una web administrable para escuelas, cuenta
                con secciones para gestionar los alumnos, roles, una sección de
                novedades en la que la comunidad educativa puede dejar su
                comentario e interactuar con el profesor, areas para los
                alumnos, subida de archivos y más.
              </p>
             
            </div>
            <a  target="_blank"  rel="noreferrer"  href="https://epet20-86711.web.app/" className="btn btn-primary btn-lg m-4">
                Ir al sitio web.
              </a>
          </div>
        </div>
      </div>
    </div>
  );
};
