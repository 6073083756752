import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { SocialMedia } from "../components/contact/SocialMedia";
import { SendEmail } from "../components/contact/SendEmail";
//
export const Contact = () => {
  useEffect(() => {
    document.title = "Contáctame - Ezequiel López";
  }, []);

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ velocity: 0.5 }}
    >
      <div className="background ">
        <div>
          <h1 className="text-white title text-center font-bold w-100 ">
            {"¿Necesitas una solución? Contáctame.".toUpperCase()}
          </h1>
        </div>
      </div>
      <div className="bg-white shadow-xl rounded-xl container mt-4 ">
        <div className="container">
          <div className="row p-4">
            <h1 className="text-center ">Contácto</h1>
            <SendEmail />

            <h1 className="text-center mb-4 mt-4 ">Redes sociales</h1>
            <SocialMedia />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
