import React from "react";
import "./App.css";
import { LoadingSpinner } from "./components/loading/loading";
import { AppRouter } from "./routers/AppRouter";

function App() {
  const [load, setLoaded] = React.useState(false);
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoaded(true);
    }
    return () => setLoaded(false);
  }, []);
  return (
    <div className="App">
      {load === true ? <AppRouter /> : <LoadingSpinner text="Cargando..." />}
    </div>
  );
}

export default App;
