import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export const Error404 = () => {
  useEffect(() => {
    document.title = "404";
  }, []);
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="text-white">
        <div className="text-center">
          <div className="">
            <h1 className="font-black title">404</h1>
            <h1>¡Oops! Esta sección no existe en la web.</h1>
            <a href="/inicio" className="btn btn-primary">
              Volver
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
